<template>
    <onboarding-layout
        :title="title"
        :loading="loading"
        :loading-title="loadingTitle"
        :error-text="errorText"
        ref="onboardingLayout"
    >
        <form-container
            id="applicationForm"
            ref="basicInfoForm"
            @on-submit="onSubmit"
            @onError="onError"
        >
            <section-header class="mt-5 mb-0">
                {{ secondaryHeading }}
            </section-header>

            <div class="row g-2 mt-2">
                <div class="col mt-0">
                    <form-field
                        v-model="secondaryFirstName"
                        data-testid="personal-info-secondary-first-name-input-add-coapp"
                        name="secondaryFirstName"
                        :label="$t('components.applicationForm.placeholder.firstName')"
                        :placeholder="$t('components.applicationForm.placeholder.firstName')"
                        validation-rules="required|min:2"
                    />
                </div>
                <div class="col mt-0">
                    <form-field
                        v-model="secondaryLastName"
                        data-testid="personal-info-secondary-last-name-input-add-coapp"
                        name="secondaryLastName"
                        :label="$t('components.applicationForm.placeholder.lastName')"
                        :placeholder="$t('components.applicationForm.placeholder.lastName')"
                        validation-rules="required|min:2"
                    />
                </div>
            </div>

            <form-field-ssn
                v-model="secondarySSN"
                data-testid="personal-info-secondary-ssn-input-add-coapp"
                name="secondarySSN"
                class="mt-2"
                last-four-only
            />

            <form-field-date
                class="mt-2"
                data-testid="personal-info-secondary-dob-input-add-coapp"
                v-model="secondaryDOB"
                name="secondaryDOB"
                validation-rules="required|dateRule|ageRule"
                :label="$t('components.applicationForm.placeholder.dateOfBirth')"
                :placeholder="$t('components.applicationForm.placeholder.dateOfBirth')"
                :focus-placeholder="$t('components.applicationForm.placeholder.dob')"
            />

            <form-radio-group
                class="mt-2 radio-left-aligned"
                data-testid="personal-info-marital-input-add-coapp"
                :options="maritalStatusOptions"
                name="secondaryMaritalStatusInput"
                v-model="secondaryMaritalStatus"
                validation-rules="required"
                :inline="true"
            />

            <section-header class="mt-5">
                {{ 'JOINT APPLICANT INCOME' }}
            </section-header>

            <form-field-currency
                class="mt-2"
                data-testid="personal-info-stated-income-add-coapp"
                v-model="secondaryIncome"
                :label="$t('pages.origination.statedIncome.placeholder')"
                :placeholder="$t('pages.origination.statedIncome.placeholder')"
                name="secondaryIncome"
                validation-rules="required|currency:10000"
            />

            <form-button
                class="mt-4"
                data-testid="personal-info-submit-button-add-coapp"
                image-src="global/web_16_lock.svg"
                :label="buttonCta"
                :submitting="submitting"
                type="submit"
                event-name="click_button_submit_add_co_applicant_info_form"
            />

            <safe-credit-score class="mb-0 mt-1" />

            <div class="d-none d-md-block text-start mt-3">
                <p
                    class="text-muted small text-start mb-2"
                    v-html="$t('components.applicationForm.legal.softPullHtml')"
                />
                <p class="text-muted small text-start mb-2">
                    For more important information about our Aven Home Cards:
                    <a
                        @click="getDocument(LegalDocumentTypes.pricingAndTerms)"
                        href="#"
                    > Pricing and Terms</a> and
                    <a
                        @click="getDocument(LegalDocumentTypes.earlyHELOCDisclosure)"
                        href="#"
                    > Early HELOC Disclosure </a>
                </p>
                <p
                    class="text-start text-muted mt-2 mb-0 small"
                    v-html="$t('pages.origination.ssnVerification.legalText')"
                />
            </div>
        </form-container>
        <template #sidebar>
            <ul class="list-unstyled list-row">
                <help-list-item />
            </ul>
            <div class="d-block d-md-none text-start">
                <p
                    class="text-muted small text-start mb-2"
                    v-html="$t('components.applicationForm.legal.softPullHtml')"
                />
                <p class="text-muted small text-start mb-2">
                    For more important information about our Aven Home Cards:
                    <a
                        @click="getDocument(LegalDocumentTypes.pricingAndTerms)"
                        href="#"
                    > Pricing and Terms</a> and
                    <a
                        @click="getDocument(LegalDocumentTypes.earlyHELOCDisclosure)"
                        href="#"
                    > Early HELOC Disclosure </a>
                </p>
                <p
                    class="text-start text-muted mt-2 mb-0 small"
                    v-html="$t('pages.origination.ssnVerification.legalText')"
                />
            </div>
        </template>
    </onboarding-layout>
</template>

<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import format from '@/mixins/format'
    import originationMixin from '@/mixins/originationMixin'
    import personalInfoMixin from '@/mixins/personalInfoMixin'
    import { logger } from '@/utils/logger'
    import { i18n } from '@/utils/i18n'
    import { localStorageKey, appSessionStorage } from '@/utils/storage'
    import { createCoApplicantLead } from '@/services/auth'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import FormContainer from '@/components/base/FormContainer'
    import SectionHeader from '@/components/SectionHeader'
    import FormField from '@/components/base/FormField'
    import FormFieldDate from '@/components/base/FormFieldDate'
    import FormFieldSsn from '@/components/base/FormFieldSsn'
    import FormButton from '@/components/base/FormButton'
    import FormRadioGroup from '@/components/base/FormRadioGroup'
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import { getDocumentForApplicantAndOpen } from '@/utils/document'
    import HelpListItem from '@/components/onboarding/HelpListItem'
    import SafeCreditScore from '@/components/SafeCreditScore'
    import assert from 'assert'
    import FormFieldCurrency from '@/components/base/FormFieldCurrency'
    import { updateStatedIncome } from '@/services/loanApplication'
    import { originationPagePaths } from '@/routes/originationRoutes'
    import { LegalDocumentTypes, MaritalStatus } from '@/services/api'

    export default {
        components: {
            'safe-credit-score': SafeCreditScore,
            'help-list-item': HelpListItem,
            'form-button': FormButton,
            'form-field-date': FormFieldDate,
            'form-field': FormField,
            'section-header': SectionHeader,
            'form-container': FormContainer,
            'form-field-ssn': FormFieldSsn,
            'form-field-currency': FormFieldCurrency,
            'onboarding-layout': OnboardingLayout,
            FormRadioGroup,
        },
        mixins: [format, originationMixin, personalInfoMixin, experimentsMixin],
        data() {
            return {
                LegalDocumentTypes,
                loading: true,
                loadingTitle: i18n.t('global.loadingMessage.verifying'),
                inJointForm: true,
                buttonCta: i18n.t('global.cta.continue'),
                secondarySSN: '',
                secondaryDOB: '',
                secondaryIncome: '',
                secondaryFirstName: '',
                secondaryLastName: '',
                secondaryMaritalStatus: '',
                maritalStatusOptions: [
                    { name: i18n.t('pages.origination.personalInfo.maritalStatus.married'), value: MaritalStatus.MARRIED },
                    { name: i18n.t('pages.origination.personalInfo.maritalStatus.unmarried'), value: MaritalStatus.UNMARRIED },
                    { name: i18n.t('pages.origination.personalInfo.maritalStatus.separated'), value: MaritalStatus.SEPARATED },
                ],
            }
        },
        computed: {
            title() {
                return 'All we need is a little information about the joint applicant...'
            },
            secondaryHeading() {
                return 'JOINT APPLICANT'
            },
            isApplicantDuplicated() {
                return this.secondaryFirstName.toLowerCase() === this.primaryFirstName.toLowerCase() && this.secondaryLastName.toLowerCase() === this.primaryLastName.toLowerCase()
            },
        },
        mounted: async function () {
            if (!this.hasCoApplicant) {
                try {
                    const authResponse = await createCoApplicantLead()
                    assert(authResponse.data.success, 'Failed to create co-applicant JWT!')
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                    return
                }
            }
            this.loading = false
            this.$logEvent('view_add_co_applicant_info')
        },
        methods: {
            onError(errorText) {
                this.errorText = errorText
                this.scrollToTop()
            },
            scrollToTop() {
                document.body.scrollTop = 0 // For Safari
                document.documentElement.scrollTop = 0
            },
            onSubmit: async function () {
                this.submitting = true

                this.errorText = this.isApplicantDuplicated ? 'It looks like you entered your own information. Please provide the information of the joint applicant.' : ''

                // Check if base is valid
                const isValid = await this.$refs.basicInfoForm.$refs.observer.validate()
                if (!isValid || isNaN(parseInt(this.secondaryIncome)) || this.isApplicantDuplicated) {
                    // 1. scroll validation errors into view
                    const el = document.querySelector('.is-invalid:first-of-type')
                    el?.scrollIntoView()

                    if (this.errorText) {
                        // 2. if there's errorText, scroll to the top
                        this.scrollToTop()
                    }

                    this.submitting = false
                    return
                }

                this.saveDataToStorage()
                try {
                    const isSuccessful = await this.submitBasicInfo({
                        firstName: this.secondaryFirstName,
                        lastName: this.secondaryLastName,
                        ssn: this.secondarySSN,
                        applicantDOB: this.secondaryDOB,
                        maritalStatus: this.secondaryMaritalStatus,
                    })
                    if (!isSuccessful) {
                        return
                    }

                    await updateStatedIncome(this.secondaryIncome, true)
                    appSessionStorage.setItem(localStorageKey.coApplicantStatedIncome, this.secondaryIncome)
                    appSessionStorage.setItem(localStorageKey.addedCoApplicantOnFailure, 'True')
                    appSessionStorage.setItem(localStorageKey.coApplicantMaritalStatus, this.secondaryMaritalStatus)

                    // Manual re-direction to co-applicant verify identity. Technically forms a loop in the flow but
                    // it doesnt actually loop as this branch is only triggered if there is no co-applicant.
                    return await this.$router.push(originationPagePaths.CO_APPLICANT_VERIFY_IDENTITY)
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                } finally {
                    this.submitting = false
                }
            },
            saveDataToStorage: function () {
                appSessionStorage.setItem(localStorageKey.coApplicantFirstName, this.secondaryFirstName)
                appSessionStorage.setItem(localStorageKey.coApplicantLastName, this.secondaryLastName)
            },
            updateBasicInfo: function (value) {
                this.secondaryFirstName = value.firstName || this.secondaryFirstName
                this.secondaryLastName = value.lastName || this.secondaryLastName
                this.secondaryDOB = value.applicantDOB || this.secondaryDOB
            },
            getDocument: async function (docType) {
                try {
                    this.loadingTitle = 'Loading Document...'
                    this.loading = true
                    this.$logEvent('click_link_get_document', { docType })
                    await getDocumentForApplicantAndOpen(docType)
                } catch (e) {
                    logger.fatal(`failed to open pdfs document`, null /* event */, e)
                    this.errorText = ApiErrorHandler(e)
                }
                this.loading = false
                this.loadingTitle = i18n.t('global.loadingMessage.verifying')
            },
        },
    }
</script>

<style scoped>
    .radio-left-aligned {
        margin-left: -1.5em;
    }
</style>
